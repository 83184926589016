class SurtaxInfoModel {
  constructor() {
    this.electroWrite = ""; // 전자 E: ELECT , 수기 W: WRITE
    this.exclusionOfDeductionReason = ""; // 불공제사유
    this.receiptBill = ""; // 영수:R/청구:D
    this.taxCode = ""; // 세금유형
    this.vatAssetType = ""; // 자산구분
    this.voucherCid = ""; // 전표 번호
  }

  setData(obj) {
    this.electroWrite = obj.electroWrite;
    this.exclusionOfDeductionReason = obj.exclusionOfDeductionReason;
    this.receiptBill = obj.receiptBill;
    this.taxCode = obj.taxCode;
    this.vatAssetType = obj.vatAssetType;
    this.voucherCid = obj.voucherCid;
  }

  getData() {
    let obj = {
      electroWrite: this.electroWrite,
      exclusionOfDeductionReason: this.exclusionOfDeductionReason,
      receiptBill: this.receiptBill,
      taxCode: this.taxCode,
      vatAssetType: this.vatAssetType,
      voucherCid: this.voucherCid,
    };

    return obj;
  }
}

export default SurtaxInfoModel;
